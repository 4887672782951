import { useSelector, useDispatch} from "react-redux";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from '@mui/material/Button';
import {setActiveDevice} from '../../app/reducers/index'

//place holder content
// const DevicePage = (props)=>{
//    return <CenterAlignedBox sx={{flexGrow:1}}>
//       <Typography variant="h6">Devices page</Typography>
//    </CenterAlignedBox>
// }
//real page
const DevicePage = (props)=>{
   const dispatch = useDispatch();
   const devices = useSelector((state)=>{
      return state.root.devices
   })
   const activeStb = useSelector((state)=>state.root.activeStb);
   const handleClick = (serialNumber, event)=>{
      console.log("Switching control to " + serialNumber);
      dispatch(setActiveDevice(serialNumber));
   }

   return <Grid direction='column' spacing={1} container id='devices-container' sx={{
      flexGrow:1,
      'paddingLeft':'48px', 
      'paddingRight':'48px',
      '@media (max-width: 358px)':{
         'paddingLeft':'24px',
         'paddingRight':'24px',
      },
      }}>
      {Object.keys(devices).map((serialNumber)=>{

         return <Grid item>
            <Paper variant="outlined" elevation={0} sx={{background: 'rgba(18, 18, 18, 0.4)'}}>
               <Box p={1} sx={{display:'flex', 'justifyContent': 'space-between'}}>
                  <Typography>{devices[serialNumber]}</Typography>
                  {activeStb === serialNumber ? 'Currently Active': <Button sx={{padding:'0px'}} size='small' onClick={handleClick.bind(this, serialNumber)}>Control</Button>}
               </Box>
            </Paper>
         </Grid>
      })}
   </Grid>
}

export default DevicePage;